var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logout" }, [
    _c(
      "div",
      { staticClass: "content-wrapper" },
      [
        _c(
          "router-link",
          {
            attrs: { to: "/" },
            nativeOn: {
              click: function ($event) {
                return _vm.$analytics.trackEventBtnNavBar("Home")
              },
            },
          },
          [_c("LincolnEffortlessExperienceLogoDark", { staticClass: "logo" })],
          1
        ),
        _vm._m(0),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("p", [
        _vm._v("Thank you for being a part of Lincoln Effortless Experience"),
      ]),
      _c("p", [_vm._v("You have successfully logged out")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }