<template>
  <div class="logout">
    <div class="content-wrapper">
      <router-link @click.native="$analytics.trackEventBtnNavBar('Home')" to="/">
        <LincolnEffortlessExperienceLogoDark class="logo" />
      </router-link>
      <div class="text">
        <p>Thank you for being a part of Lincoln Effortless Experience</p>
        <p>You have successfully logged out</p>
      </div>
    </div>
  </div>

</template>

<script>
import LincolnEffortlessExperienceLogoDark from '../components/LincolnEffortlessExperienceLogoDark.vue';

export default {
  name: 'Logout',
  components: {
    LincolnEffortlessExperienceLogoDark,
  },
  mounted() {
    const analytic = {
      analyticType: 'LogOff',
      resourceId: null,
      userId: this.$store.getters['userStore/userId'],
    };
    this.$store.dispatch('analyticStore/add', analytic);
    this.$store.dispatch('userStore/logout');
  },
  created() {
    this.$analytics.trackPageView();
  },
};
</script>

<style lang="scss" scoped>
.logout {
  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .logo {
      display: inline-block;
      width: 100%;
      max-width: 240px;
      margin-top: 15px;
    }

    .text {
      margin: auto 0;

      p {
        font-size: 0.75rem;
        text-align: center;
        font-weight: $bold;

        @include respond(sm) {
          font-size: 0.875rem;
        }

        @include respond(md) {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
